<template>
  <div class="content-wrapper">
    <v-layout>
      <v-flex>
        <v-card flat>
          <v-card-text class="pt-6">
            <v-skeleton-loader class="widget-card" v-if="data.length === 0 && loading" :loading="loading" type="table-row-divider@6"></v-skeleton-loader>
            <v-row v-if="data.length === 0 && !loading">
              <v-col cols="12">
                <p class="d-flex align-center justify-center graph-title">No Data found</p>
              </v-col>
            </v-row>
            <v-row v-for="(item,index) in data" :key="index" v-if="data.length > 0 && !loading">
              <v-col cols="12">
                <div class="d-flex align-center mt-5">
                  <p class="graph-title mb-4">{{item.branch }}</p>
                  <p class="graph-title mb-4 mx-2">-</p>
                  <p class="graph-title mb-4">
                    <span v-if="item.branch === 'Bangladesh'">Avg Deal Value: {{item.average_deal_value | currency('TK')}}</span>
                    <span v-else>Avg Deal Value: {{item.average_deal_value | currency('£')}}</span>
                  </p>
                </div>
                <v-row>
                  <v-col lg="3" xl="3" md="4" sm="6" xs="12" v-for="(val,key) in item.staffs" :key="key">
                    <div class="performance-card" :class="`${val.color.toLowerCase()}--color`">
                      <div class="d-flex align-center flex-column">
                        <div class="symbol bg-white-100">
                          {{getInitials(val.name)}}
                        </div>
                        <p class="font-weight-bold font-16 mb-3 text-center">{{val.name | capitalize}}</p>
                      </div>
                      <div class="details">
                        <div class="item d-flex align-center justify-space-between">
                          <p class="name">Deals Count</p>
                          <p class="value">{{val.deal_count}}</p>
                        </div>
                        <div class="item d-flex align-center justify-space-between">
                          <p class="name">Deal Value</p>
                          <p class="value">{{val.deal_value | currency('£')}}</p>
                        </div>
                        <div class="item d-flex align-center justify-space-between">
                          <p class="name">Deposit Taken</p>
                          <p class="value">{{val.deposit_taken | currency('£')}}</p>
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import globalFn from '../../../mixins/globalFn';
export default {
  mixins: [globalFn],
  data() {
    return {
      data: [],
      loading: false
    }
  },
  mounted() {
    this.$root.$on('searchSalesDashboard', this.searchSalesDashboard)
  },
  methods: {
    async searchSalesDashboard(data) {
      this.loading = true
      let payload = {}
      payload.search = data.search
      payload.start_date = data.start_date
      payload.end_date = data.end_date
      payload.branch_ids = data.branch
      payload.deal_value_from = data.deal_value_from
      payload.deal_value_to = data.deal_value_to
      payload.deal_taken_from = data.deal_taken_from
      payload.deal_taken_to = data.deal_taken_to
      payload.employee = data.employee
      payload.type = data.type
      await this.$store.dispatch('salesdashboard/GET_PABAU_SALES_ANALYSIS', payload).then(res => {
        console.log(res.data.data)
        if (res.result) {
          this.loading = false
          this.data = res.data.data
        }

      })
    }
  }
}

</script>
<style scoped lang="scss">
.performance-card {
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: black;
  box-shadow: rgba(0, 0, 0, 0.03) 0px 3px 4px 0px;

  .symbol {
    border-radius: 50%;
    padding: 10px;
    width: 65px;
    height: 65px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 5px;
  }

  .employee {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    text-align: center;
  }

  &.green--color {
    background-color: rgba(74, 206, 40, 0.08);
    border: 1px solid #4ACE28;
  }

  &.red--color {
    background-color: #fff0f3;
    border: 1px solid rgb(255, 104, 107);
  }

  .details {
    display: flex;
    flex-direction: column;

    .item {

      display: flex;
      align-items: flex-start;
      margin-bottom: 8px;


      .name {
        color: rgb(153, 161, 183);
        font-size: 13px;
        font-weight: 500;
        text-align: center;
      }

      .value {
        color: rgb(75, 86, 117);
        font-size: 14px;
        font-weight: 700;
        text-align: center;
      }

    }
  }

}

</style>
