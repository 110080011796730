<template>
  <div class="d-flex">
    <v-text-field flat prepend-inner-icon="mdi-magnify" autocomplete="off" label="Search here…" solo hide-details class="search-input" @input="isTyping = true" v-model="search">
      <template v-slot:append>
        <v-menu :close-on-content-click="false" offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="b-btn b-advance bg-white-100" depressed v-on="on">
              Advanced
              <v-icon color="rgba(0, 0, 0, 0.54)" class="mt-1" right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <div class="advance-sec">
            <div class="head">
              Advanced Search
              <v-chip small class="este-chip yellow-chip float-right" text-color="black" @click="searchKey">
                Filter
              </v-chip>
              <v-chip small color="#221F20" class="este-chip mb-2 float-right" text-color="#FFFFFF" @click="reset">
                Reset
              </v-chip>
            </div>
            <v-divider></v-divider>
            <v-row class="filter-row">
              <v-col cols="12" md="6" sm="12" class="pa-5">
                <span class="heading">Date Range:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-menu v-model="enquiryDateFromMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" :value="dateUkFormat(filter.start_date)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date from" prepend-inner-icon="mdil-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.start_date" no-title @input="enquiryDateFromMenu = false"></v-date-picker>
                  </v-menu>
                  <v-menu v-model="enquiryDateToMenu" :close-on-content-click="false" transition="scale-transition" offset-y max-width="290px" min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field autocomplete="off" :value="dateUkFormat(filter.end_date)" solo outlined v-on="on" flat hide-details="auto" class="advance-input" placeholder="Date to" prepend-inner-icon="mdil-calendar"></v-text-field>
                    </template>
                    <v-date-picker v-model="filter.end_date" no-title @input="enquiryDateToMenu = false"></v-date-picker>
                  </v-menu>
                </div>
                <span class="heading">Deal Value:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-text-field autocomplete="off" solo outlined flat hide-details="auto" class="advance-input" placeholder="From" v-model="filter.deal_value_from"></v-text-field>
                  <v-text-field autocomplete="off" solo outlined flat hide-details="auto" class="advance-input" placeholder="To" v-model="filter.deal_value_to"></v-text-field>
                </div>
                <span class="heading">Deposit Taken:</span>
                <div class="d-flex mt-2 mb-4">
                  <v-text-field autocomplete="off" solo outlined flat hide-details="auto" class="advance-input" placeholder="From" v-model="filter.deal_taken_from"></v-text-field>
                  <v-text-field autocomplete="off" solo outlined flat hide-details="auto" class="advance-input" placeholder="To" v-model="filter.deal_taken_to"></v-text-field>
                </div>
              </v-col>
              <v-divider vertical></v-divider>
              <v-col cols="12" md="6" sm="12" class="pa-5">
                <span class="heading">Branch:
                  <v-checkbox hide-details class="shrink mt-0" v-model="BranchToggle" @click="handleFunction('BranchCheckUncheck')" :ripple="false"></v-checkbox>
                </span>
                <div class="mt-2">
                  <v-chip small class="este-chip tag-chip mb-2 text-decoration-line-through" :class="(BranchBlank === true)  ? 'selected' : ''" text-color="black" @click.stop="selectBranchBlank">
                    NONE
                  </v-chip>
                  <template v-for="branch in BrachDefault">
                    <v-chip small class="este-chip tag-chip mb-2" :class="(branch.active === true)  ? 'selected' : ''" text-color="black" @click="branchToggle(branch)">
                      {{ branch.branch | capitalize }}
                    </v-chip>
                  </template>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>
<script>
import advancedSearch from '../../mixins/advancedSearch';
import globalFn from '../../mixins/globalFn';
import status from '../../mixins/status';
import eventBus from '../../eventBus.js';
import _ from 'lodash';
export default {
  name: "leadSearch",
  mixins: [globalFn, status, advancedSearch],
  props: {
    screen: {
      type: String,
      required: true
    },
    slug: {
      type: String,
      required: true
    }
  },
  data: () => ({
    enquiryDateFromMenu: false,
    enquiryDateToMenu: false,
    user: JSON.parse(localStorage.getItem('user')),
    filter: {
      search: '',
      start_date: '',
      end_date: '',
      deal_value_from: '',
      deal_value_to: '',
      deal_taken_from: '',
      deal_taken_to: '',
      branch: [],
      employee: "",
      type: "branch"
    },
    search: "",
    isTyping: false,
  }),
  watch: {
    search: _.debounce(function() {
      this.isTyping = false;
    }, 1000),
    isTyping: function(value) {
      if (!value) {
        this.filter.employee = this.search
        this.checkAll()
        this.searchKey();
      }
    }
  },
  mounted() {
    this.initialise()
  },
  methods: {
    async initialise() {
      await this.loadDefaultBranch()
      await this.setDateRange()
      await this.searchKey()
    },
    setDateRange() {

      let today = new Date();
      let startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      let endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      this.filter.start_date = this.formatDate(startDate);
      this.filter.end_date = this.formatDate(endDate);
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2);
      let day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`;
    },
    async searchKey() {
      this.$root.$emit('searchSalesDashboard', this.filter)
    },
    async reset() {
      await this.resetFilter()
      await this.loadDefaultBranch()
    },
    async resetFilter() {
      this.filter.search = ''
      this.filter.start_date = ''
      this.filter.end_date = ''
      this.filter.deal_value_to = ''
      this.filter.deal_value_from = ''
      this.filter.deal_taken_to = ''
      this.filter.deal_taken_from = ''
      this.filter.employee = ''
      this.filter.type = 'branch'
      this.filter.branch_ids = []
    }
  }
};

</script>
