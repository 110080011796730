import collection from '../../api/collections/SalesDashboard'
import asyncMiddleware from '../../utils/action-handler'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {

    async GET_PABAU_SALES_ANALYSIS({ commit, dispatch }, payload) {
      return await asyncMiddleware(async function() {
        const { data } = await collection.salesDashboard(payload)
        return data
      }, commit, dispatch, 'GET_PABAU_SALES_ANALYSIS', true)
    }
  }
}
